import React from 'react'
import './footer.css'
import CookieWidgetComponent from '../cookieBanner/CookieWidgetComponent';

// icons
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      <a href='/#' className='footer__logo'>Wiktor Koscielny</a>

      <ul className='permalinks'>
        <li><a href='/#'>Home</a></li>
        <li><a href='/#about'>About</a></li>
        <li><a href='/#experience'>Experience</a></li>
        <li><a href='/#advantages'>Advantages</a></li>
        <li><a href='/#portfolio'>Portfolio</a></li>
        <li><a href='/#courses'>Courses</a></li>
        <li><a href='/#contact'>Contact</a></li>
      </ul>

      <CookieWidgetComponent />

      <div className='footer__socials'>
        <a href='https://github.com/wiktorkoscielny' target="_blank" rel="noreferrer"><FaGithub /></a>
        <a href='https://www.linkedin.com/in/wiktorkoscielny/' target="_blank" rel="noreferrer"><BsLinkedin /></a>
      </div>

      <div className='footer__copyright'>
        <small>&copy; Wiktor Koscielny</small>
      </div>
    </footer>
  );
};

export default Footer;
