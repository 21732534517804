import React from 'react';
import './glitch.css';
const GlitchComponent = ({text}) => {
  const stackCount = 3; // Number of stack items

  const stackItems = Array.from({ length: stackCount }, (_, index) => (
    <span key={index} style={{ '--index': index }}>
      {text}
    </span>
  ));

  return (
    <div className="stack" style={{ '--stacks': stackCount }}>
      {stackItems}
    </div>
  );
};

export default GlitchComponent;