import React from "react";
import "./_module.css";
import { useStore } from "../../store/store";
import { modal_content_description, action_type_allow_all, action_type_reject_all, action_type_save_prefs } from "./config";
import CookieGroupsComponent from "./CookieGroupsComponent";

const CookieModalComponent = () => {
  const [, dispatch] = useStore(); // Destructure the dispatch function

  const allowAll = () => {
    setCheckboxValues(action_type_allow_all);

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 180);

    const expires = expirationDate.toUTCString();
    const state = { essential: "allowed", analytics: "allowed" };

    localStorage.setItem("cookieConsent", JSON.stringify(state));
    const encodedState = encodeURIComponent(JSON.stringify(state));
    document.cookie = `cookieConsent=${encodedState}; expires=${expires}; path=/`;

    dispatch({ type: "CLOSE_ALL"});
  };

  const rejectAll = () => {
    setCheckboxValues(action_type_reject_all);

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 180);

    const expires = expirationDate.toUTCString();
    const state = { essential: "allowed", analytics: "denied" };

    // Set state in localStorage
    localStorage.setItem("cookieConsent", JSON.stringify(state));

    // Set cookie with encoded state
    const encodedState = encodeURIComponent(JSON.stringify(state));
    document.cookie = `cookieConsent=${encodedState}; expires=${expires}; path=/`;

    dispatch({ type: "CLOSE_ALL"});
  };

  const getCheckboxValuesUnderParent = (parentSelector) => {
    const parentElement = document.querySelector(parentSelector);
    if (!parentElement) return {};

    const checkboxes = parentElement.querySelectorAll('input[type="checkbox"]');
    let checkboxValues = {};

    checkboxes.forEach((checkbox) => {
      const status = checkbox.checked ? "allowed" : "denied";
      const id = checkbox.id.substring("checkbox-".length);
      checkboxValues[id] = status;
    });

    return checkboxValues;
  };

  const setCheckboxValues = (actionType, data = null) => {
    const parentElement = document.querySelector(".cookie-modal-wrapper");
    if (!parentElement) return;

    const checkboxes = parentElement.querySelectorAll('input[type="checkbox"]');

    if (actionType === action_type_allow_all) {
        checkboxes.forEach((checkbox) => {
            if (!checkbox.classList.contains("restricted")) {
                checkbox.checked = true;
            }
        });
    } else if (actionType === action_type_reject_all) {
        checkboxes.forEach((checkbox) => {
            if (!checkbox.classList.contains("restricted")) {
                checkbox.checked = false;
            }
        });
    } else if (actionType === action_type_save_prefs) {
        checkboxes.forEach((checkbox) => {
            if (!checkbox.classList.contains("restricted")) {
                const id = checkbox.id.substring("checkbox-".length);
                if (data.hasOwnProperty(id) && data[id] === "allowed") {
                    checkbox.checked = true;
                } else if (data.hasOwnProperty(id) && data[id] === "denied") {
                    checkbox.checked = false;
                }
            }
        });
    }
  };

  const savePreferences = () => {
    const state = getCheckboxValuesUnderParent(".cookie-modal-wrapper");
    setCheckboxValues(action_type_save_prefs, state);

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 180);

    const expires = expirationDate.toUTCString();

    // Set state in localStorage
    localStorage.setItem("cookieConsent", JSON.stringify(state));

    // Set cookie with encoded state
    const encodedState = encodeURIComponent(JSON.stringify(state));
    document.cookie = `cookieConsent=${encodedState}; expires=${expires}; path=/`;

    dispatch({ type: "CLOSE_ALL"});
  };

  const closeModal = () => {
    dispatch({ type: 'TOGGLE_COOKIE_MODAL', payload: false });
  }

  return (
    <>
      <div className="cookie-modal-overlay" onClick={closeModal}></div>
      <div className="cookie-modal-wrapper">
        <div className="cookie-header">
          <div>
            <h3>Consent Preferences Center</h3>
          </div>
          <div className="close" data-role="cookie-modal-close" onClick={closeModal}>
            x
          </div>
        </div>
        <div className="content">
          <div className="subtitle">
            <h4>Cookie Usage</h4>
          </div>
          <div className="description">
            <span>{modal_content_description}</span>
          </div>
          <div className="groups-container">
            <CookieGroupsComponent />
          </div>
        </div>
        <div className="cookie-footer">
          <div>
            <button
              className="btn btn-primary accept-all"
              data-role="allow-all"
              onClick={allowAll}
            >
              Allow all
            </button>
            <button
              className="btn btn-primary reject-all"
              data-role="reject-all"
              onClick={rejectAll}
            >
              Reject all
            </button>
          </div>
          <button
            className="btn read-more save"
            data-role="save-preferences"
            onClick={savePreferences}
          >
            Save preferences
          </button>
        </div>
      </div>
    </>
  );
};

export default CookieModalComponent;
