import React from "react";
import "./Placeholder.css";
import { PROFILE_PICTURE_TYPE } from "../header/Header.config";
import { TESTIMONIALS_TYPE } from "../testimonials/Testimonials.config";
import { PORTFOLIO_TYPE } from "../portfolio/Portfolio.config";
import { ABOUT_SECTION_TYPE } from '../about/About.config';
import { HEXAGON_TYPE } from "../certificate/Certificate.type";

const Placeholder = (props) => {
  const { type } = props;

  const SwitchPlaceholderType = (type, className) => {
    switch (type) {
      case PROFILE_PICTURE_TYPE:
        return `Profile_${className}`;
      case TESTIMONIALS_TYPE:
        return `Testimonials_${className}`;
      case PORTFOLIO_TYPE:
        return `Portfolio_${className}`;
      case ABOUT_SECTION_TYPE:
        return `About_${className}`
      case HEXAGON_TYPE:
        return `Hexagon_${className}`
    }
}

  return (
    <div
      className={SwitchPlaceholderType(type, "Placeholder_Wrapper")}
    >
      <div
        className={SwitchPlaceholderType(type, "Placeholder_Content")}
      />
    </div>
  );
};

export default Placeholder;
