import React, { useEffect, useState } from "react";
import { cookie_groups_data } from "./config";
import { MdKeyboardArrowDown } from "react-icons/md";

const CookieGroupsComponent = () => {
  const [activeTabs, setActiveTabs] = useState([]);

  useEffect(() => {
    const parentElement = document.querySelector(".cookie-modal-wrapper");
    const checkboxes = parentElement.querySelectorAll('input[type="checkbox"]');
    const localData = JSON.parse(localStorage.getItem("cookieConsent"));

    checkboxes.forEach((checkbox) => {
      if (!checkbox.classList.contains("restricted")) {
        if (localData) {
          const id = checkbox.id.substring("checkbox-".length);

          if (localData.hasOwnProperty(id) && localData[id] === "allowed") {
            checkbox.checked = true;
          } else if (
            localData.hasOwnProperty(id) &&
            localData[id] === "denied"
          ) {
            checkbox.checked = false;
          }
        } else {
          checkbox.checked = false;
        }
      }
    });
  }, []);

  const toggleDropdown = (id) => {
    if (activeTabs.includes(id)) {
      setActiveTabs(prevTabs => prevTabs.filter(tab => tab !== id));
    } else {
      setActiveTabs(prevTabs => [...prevTabs, id]);
    }
  };

  return (
    <>
      {cookie_groups_data.map(
        ({ name, id, description, is_restricted, index }) => {
          return (
            <div
              className="groups-item"
              data-role="cookie-groups-item"
              key={index + id}
            >
              <div className="tab" data-role={"group-tab-" + id} onClick={() => toggleDropdown(id)}>
                <div className={activeTabs.includes(id) ? 'title expanded' : 'title'}><MdKeyboardArrowDown />{name}</div>
                <div
                  className={
                    is_restricted ? "container restricted" : "container"
                  }
                >
                  <label className="switch" htmlFor={"checkbox-" + id}>
                    {is_restricted ? (
                      <input
                        className="restricted"
                        type="checkbox"
                        id={"checkbox-" + id}
                        data-input-id={id}
                        checked={true}
                        readOnly={true}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        id={"checkbox-" + id}
                        data-input-id={id}
                      />
                    )}
                    <div className="slider round"></div>
                  </label>
                </div>
              </div>
              <div className={activeTabs.includes(id) ? 'content' : 'content hidden'} data-role={"group-tab-content-" + id}>
                <span>{description}</span>
              </div>
            </div>
          );
        }
      )}
    </>
  );
};

export default CookieGroupsComponent;
