import React from 'react';
import DOMPurify from 'dompurify';

const TextRenderer = (content) => {
  const rawHtml = content;
  const sanitizedHtml = DOMPurify.sanitize(rawHtml);

  return (
    <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  );
};

export default TextRenderer;
