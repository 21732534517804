import React, { useState, useEffect } from "react";
import "./header.css";
import CTA from "./CTA";
import HeaderSocials from "./HeaderSocials";
import { Image } from "../Image/Image.component";
import { PROFILE_PICTURE_TYPE } from "./Header.config";
import PROFILE_PICTURE from "../../assets/profile-picture.webp";
import Certificate from "../certificate/Certificate";
import GlitchComponent from "../glitch/glitch.component";

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateIsMobile);
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    const contentTimeout = setTimeout(() => {
      setIsContentVisible(true);
    }, 2000);
    return () => {
      window.removeEventListener("resize", updateIsMobile);
      clearTimeout(loadingTimeout);
      clearTimeout(contentTimeout);
    };
  }, []);

  return (
    <React.Fragment>
      <header>
        <div className="container header__container">
          <div>
            <h5>Hello I'm</h5>
            <GlitchComponent text='Wiktor Koscielny' />
            <h5
              className={`text-light loading-text ${
                isContentVisible ? "visible" : ""
              }`}
            >
              Full Stack Web Developer |{" "}
              <a
                href="/#advantages"
                className="certificate about-certificate-link"
              >
                Certified Magento Developer
              </a>
            </h5>

            <div>
              <CTA />
              <Certificate />
              <HeaderSocials />
              <Image
                src={PROFILE_PICTURE}
                alt="Wiktor Koscielny profile picture"
                type={PROFILE_PICTURE_TYPE}
              />
              <a href="/#contact" className="scroll__down">
                Scroll Down
              </a>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
