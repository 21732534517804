import React, { useRef, useState, useEffect } from "react";
import "./portfolio.css";
import { image_data, PORTFOLIO_TYPE } from "./Portfolio.config";
import { Image } from "../Image/Image.component";
import RenderWhenVisible from "../renderWhenVisible/RenderWhenVisible.component";
import TextRenderer from "../textRenderer/textRenderer.component";

const Portfolio = () => {
  const elementRef = useRef();
  const isVisible = RenderWhenVisible(elementRef);

  const [descriptionShown, setDescriptionShown] = useState({});
  const [contentHeight, setContentHeight] = useState({});

  const toggleDescription = (id) => {
    setDescriptionShown((prev) => {
      const newShownState = { ...prev };

      if (!newShownState[id]) {
        Object.keys(newShownState).forEach((itemId) => {
          newShownState[itemId] = false;
        });
        newShownState[id] = true;
      } else {
        newShownState[id] = false;
      }

      return newShownState;
    });
  };

  useEffect(() => {
    if (isVisible) {
      const newContentHeight = {};
      image_data.forEach(({ id }) => {
        const divId = "portfolio-description-" + id;
        const element = document.getElementById(divId);
        if (element) {
          newContentHeight[id] = element.scrollHeight;
        }
      });
      setContentHeight(newContentHeight);
    }
  }, [isVisible]);

  return (
    <section id="portfolio">
      <h5>My recent work</h5>
      <h2>Portfolio</h2>

      <div
        className="container portfolio__container"
        ref={elementRef}
        id="portfolio-container"
      >
        {isVisible &&
          image_data.map(
            ({
              id,
              image,
              title,
              alt,
              path,
              websiteSource,
              description,
            }) => {
              const isDescriptionOpen = descriptionShown[id];

              return (
                <article
                  key={id}
                  className={`portfolio__item ${isDescriptionOpen ? 'active' : ''}`}
                  id={"portfolio-item-" + id}
                >
                  <Image src={image} alt={alt} type={PORTFOLIO_TYPE} />
                  <h3>{title}</h3>
                  <p
                    className="portfolio__description"
                    id={"portfolio-description-" + id}
                    style={{
                      maxHeight: isDescriptionOpen ? contentHeight[id] + "px" : "0",
                      opacity: isDescriptionOpen ? 1 : 0,
                      pointerEvents: isDescriptionOpen ? 'auto' : 'none',
                      marginBottom: isDescriptionOpen ? '36px' : '0'
                    }}
                  >
                     {TextRenderer(description)}
                  </p>
                  <div className="portfolio__item-cta">
                    {websiteSource && (
                      <a
                        href={websiteSource}
                        className="btn btn-primary"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Website
                      </a>
                    )}
                      <a className="btn read-more" style={{minWidth: '128px'}} onClick={() => toggleDescription(id)}>
                        {isDescriptionOpen ? 'Read less' : 'Read more'}
                      </a>
                  </div>
                </article>
              );
            }
          )}
      </div>
    </section>
  );
};

export default Portfolio;
