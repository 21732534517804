import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://github.com/wiktorkoscielny' target="_blank" rel="noreferrer"><FaGithub /></a>
        <a href='https://www.linkedin.com/in/wiktorkoscielny/' target="_blank" rel="noreferrer"><BsLinkedin /></a>
    </div>
  );
};

export default HeaderSocials;
