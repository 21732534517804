export const LINKEDIN_API_DATA = [
  {
    author: "Carina Dutra Esteves",
    position: "Product Manager | ICT Project Manager | Industrial Engineer",
    content:
      "Wiktor is a proactive developer with a diligent and organized work approach. He's easygoing and easy to communicate with. When we worked together, he would send me videos or screenshots which helped in making decisions for tasks. Wiktor has consistently delivered high-quality work demonstrating great FE skills. Overall, he is a great teammate and it was a pleasure to work with him.",
  },
  {
    author: "Elizabete Rupā",
    position: "Promotion Specialist at Rimi | MBA student at RBS",
    content:
      "Wiktor worked as a developer in my project team, and I was more than lucky to have him as a coworker. He consistently gave 100 percent effort to the team and played a significant role in ensuring that we completed the tasks timely. He was very helpful and positive towards his teammates, well explained complicated aspects of the tasks, supported and mentored them. Wiktor often took initiative in hard task delivery, nevertheless always staying calm and organized. Any team would be lucky to have Wiktor as a collegue, and I recommend him to any business looking for new and diligent talent!",
  },
  {
    author: "Natalia Włodarczyk",
    position: "Project Manager | Film Production Manager",
    content:
      "I highly recommend Wiktor for his outstanding work and contributions as a full-stack developer. Wiktor consistently impressed us with his technical prowess, problem-solving skills, and dedication to delivering high-quality solutions. Throughout our time working together, Wiktor displayed a deep understanding of both front-end and back-end development, and he seamlessly integrated these skills to create robust and efficient applications. His ability to adapt to new technologies and his commitment to staying updated with industry trends was invaluable to our projects. Wiktor consistently met deadlines and maintained open communication, making him a reliable and collaborative team member. He took initiative and often provided creative solutions to complex problems, showing strong leadership and teamwork skills. I have no doubt that Wiktor will continue to excel in his career as a full-stack developer, and I wholeheartedly recommend him for any team or project requiring top-tier technical skills and a strong work ethic. It has been a pleasure working with him, and I look forward to witnessing his continued success in the field.",
  },
];

export const LINKEDIN_RECOMMENDATIONS_URL = 'https://www.linkedin.com/in/wiktorkoscielny/details/recommendations/';
