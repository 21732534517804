export const initialState = {
  showCookieModal: false,
  showCookieBanner: false,
  isUserConsentSaved: false,
  cookiesEssential: true,
  cookieAnalytics: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_COOKIE_BANNER':
      return { ...state, showCookieBanner: action.payload };
    case 'TOGGLE_COOKIE_MODAL':
      return { ...state, showCookieModal: action.payload };
    case 'CLOSE_ALL':
      return { ...state, showCookieModal: false, showCookieBanner: false };
      case 'SET_USER_CONSENT_STATE':
        return {
          ...state, isUserConsentSaved: true
        }
    default:
      return state;
  }
};