import React from 'react'
import './services.css'
import {BsCheck2} from 'react-icons/bs'
import Certification1 from '../../assets/AD0-E717.pdf';
import Certification2 from '../../assets/AD0-E719.pdf'

const Services = () => {
  return (
    <section id='advantages'>
      <h5>
        What I Offer
      </h5>
      <h2>
        Advantages
      </h2>

      <div className='container service__container'>
        <article className='service'>
          <div className='service__head'>
            <h3>Additional Tech Skills</h3>
          </div>

          <ul className='service__list'>
          <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Proficient in Magento 2 development</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <a
                className='certificate'
                href={Certification1}
                target="_blank"
                rel="noreferrer"
              >
                Adobe Commerce Developer Professional with Cloud Add-on (AD0-E717)
              </a>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <a
                className='certificate'
                href={Certification2}
                target="_blank"
                rel="noreferrer"
              >
                Adobe Commerce Front-End Developer (AD0-E719)
              </a>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Experience in using Docker</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Advanced knowledge of GIT version control system</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Experienced with repo hosting services like GitHub, Gitlab, Bitbucket</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>E-commerce and website performance optimization</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Experience in website SEO optimization</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Experienced with npm & yarn package menagers</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Experienced with Linux operating system</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Experience in modern styling methods</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Knowledge of RWD, mobile first approach, pixel perfection</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Familiar with Jira</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Familiar with Figma</p>
            </li>
            
          </ul>
        </article>
        {/* END OF TECH SKILLS */}
        <article className='service'>
          <div className='service__head'>
            <h3>Soft Skills</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Good communication in English</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Strong problem-solving skills</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Strong willingness to learn</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Team player</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Ability to work under pressure</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Can-do attitude</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Knowledge of Scrum and Agile methodologies</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Understanding of business and application layer meaning</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon'/>
              <p>Delivering good quality code</p>
            </li>
          </ul>
        </article>
        
      </div>
    </section>
  );
};
export default Services;