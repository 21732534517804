import React from 'react'
import './contact.css'

import { useRef } from 'react';
import emailjs from 'emailjs-com'

import {MdOutlineMail} from 'react-icons/md'
import {BsPhone} from 'react-icons/bs'

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_SERVICEID_API_KEY, process.env.REACT_APP_TEMPLATEID_API_KEY, form.current, process.env.REACT_APP_PUBLICKEY_API_KEY)
      .then((result) => {
          alert('Email sent successfully!');
      }, (error) => {
          console.log(error.text);
          alert('Somethig went wrong, check the console');
      });

      e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5 className='email_paragraph'>wiktorkoscielny@gmail.com</h5>
            <a 
              href='mailto:wiktorkoscielny.recruitment@gmail.com' 
              target='_blank'
              rel="noreferrer"
            >
                Send Message
            </a>
          </article>
          <article className='contact__option'>
            <BsPhone  className='contact__option-icon'/>
            <h4>Phone</h4>
            <h5>+48 883 283 889</h5>
            <a href='tel:+48-883-283-889' target='_blank' rel="noreferrer">Make a call</a>
          </article>

        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Company Name' required />
          <input  type='email' name='email' placeholder='Your email' required/>
          <textarea name='message' rows='7' placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>

      </div>
    </section>
  );
};
export default Contact;