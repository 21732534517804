import React from "react";

import { StoreProvider } from './store/store';
import { reducer, initialState } from './store/reducer';

import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About"
import Experience from "./components/experience/Experience"
import Services from "./components/services/Services"
import Portfolio from './components/portfolio/Portfolio'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import MatrixEffect from "./components/matrix/matrix.component";

const App = () => {
    return (
        <StoreProvider reducer={reducer} initialState={initialState}>
            <>
                <MatrixEffect />
                <Header />
                <Nav />
                <About />
                <Experience />
                <Services />
                <Portfolio />
                <Testimonials />
                <Contact />
                <Footer />
            </>
        </StoreProvider>
    )
}

export default App;
