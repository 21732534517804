import React, { useRef } from "react";
import ReadMoreReact from "read-more-react";
import "./testimonials.css";
import { Pagination, Autoplay } from "swiper";
import RenderWhenVisible from "../renderWhenVisible/RenderWhenVisible.component";
import { Image } from "../Image/Image.component";
import { Swiper, SwiperSlide } from "swiper/react";
import PLACEHOLDER_IMAGE from '../../assets/linkedin-logo.webp';
import "swiper/css";
import "swiper/css/pagination";

import { TESTIMONIALS_TYPE } from "./Testimonials.config";
import { LINKEDIN_API_DATA, LINKEDIN_RECOMMENDATIONS_URL } from "./Linkedin.config";

const Testimonials = () => {
  const elementRef = useRef();
  const isVisible = RenderWhenVisible(elementRef);

  return (
    <section className="Testimonials" id="courses" ref={elementRef}>
      <h5>More about me</h5>
      <h2>
        <a
          href={LINKEDIN_RECOMMENDATIONS_URL}
          rel="noreferrer"
          target="_blank"
        >
          Linkedin Recommendations
          </a>
      </h2>
      <Swiper
        className="container testimonials__container"
        modules={[Autoplay, Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
      >
        {isVisible &&
          LINKEDIN_API_DATA.map(
            ({ author, image, position, content }, index) => {
              return (
                <SwiperSlide key={index} className="testimonial">
                  <Image
                    src={PLACEHOLDER_IMAGE}
                    alt={author + "linkedin user profile picture"}
                    type={TESTIMONIALS_TYPE}
                    loading="lazy"
                  />
                  <h5 className="client__name">{author}</h5>
                  <h5 className="client__position">{position}</h5>
                  <small className="client__review">
                    <ReadMoreReact
                      text={content}
                      min={80}
                      ideal={150}
                      max={200}
                      readMoreText="...click here to read more"
                    />
                  </small>
                </SwiperSlide>
              );
            }
          )}
      </Swiper>
    </section>
  );
};

export default Testimonials;
