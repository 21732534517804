import React from 'react'
import { useStore } from '../../store/store';

import { banner_description } from './config'
import './_module.css';
import CookieModalComponent from './CookieModalComponent';

const CookieBannerComponent = () => {
    const [{ showCookieModal }, dispatch] = useStore();
    const toggleCookieModal = () => {
        dispatch({ type: 'TOGGLE_COOKIE_MODAL', payload: true });
    };

      const allowAll = () => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 180);
    
        const expires = expirationDate.toUTCString();
        const state = { essential: 'allowed', analytics: 'allowed' };
    
        // Set state in localStorage
        localStorage.setItem('cookieConsent', JSON.stringify(state));
    
        // Set cookie with encoded state
        const encodedState = encodeURIComponent(JSON.stringify(state));
        document.cookie = `cookieConsent=${encodedState}; expires=${expires}; path=/`;
    
        dispatch({ type: 'TOGGLE_COOKIE_BANNER', payload: false });
    };

    const rejectAll = () => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 180);
    
        const expires = expirationDate.toUTCString();
        const state = { essential: 'allowed', analytics: 'denied' };
    
        // Set state in localStorage
        localStorage.setItem('cookieConsent', JSON.stringify(state));
    
        // Set cookie with encoded state
        const encodedState = encodeURIComponent(JSON.stringify(state));
        document.cookie = `cookieConsent=${encodedState}; expires=${expires}; path=/`;

        dispatch({ type: 'TOGGLE_COOKIE_BANNER', payload: false });
    };

  return (
    <>
        <div className='cookie-banner-wrapper'>
            <div>
                <div className='banner-title'><h2>Cookies!</h2></div>
                <div className='banner-description'>
                    <span>{banner_description}</span>
                </div>
                <div className='banner-btn-wrapper'>
                    <div>
                        <button className='btn btn-primary accept-all' data-role='allow-all' onClick={allowAll}>Allow all</button>
                    <button className='btn btn-primary reject-all' data-role='reject-all' onClick={rejectAll}>Reject all</button>
                    </div>
                    <button className='btn read-more settings' data-role='adjust-settings' onClick={toggleCookieModal}>Menage preferences</button>
                </div>
            </div>
        </div>
        {showCookieModal && <CookieModalComponent />}
    </>
  )
}

export default CookieBannerComponent