import { React, useState } from "react";
import Placeholder from "../placeholder/Placeholder";
import { PROFILE_PICTURE_TYPE } from '../header/Header.config'
import { TESTIMONIALS_TYPE } from "../testimonials/Testimonials.config";
import { PORTFOLIO_TYPE } from "../portfolio/Portfolio.config";
import { ABOUT_SECTION_TYPE } from '../about/About.config';
import './Image.style.css';

export const Image = (props) => {
  const { src, alt, type } = props;
  const [isLoading, setIsLoading] = useState(true);
  const handleImageLoading = () => {
    setIsLoading(false);
  }

  const onLoadImageStyle = {
    paddingBottom: isLoading ? "56%" : "0",
    height: isLoading ? 0 : "auto"
  };

  const SwitchImageType = (type) => {
      switch (type) {
        case PROFILE_PICTURE_TYPE:
          return "me";
        case TESTIMONIALS_TYPE:
          return "client__avatar";
        case PORTFOLIO_TYPE:
          return "portfolio__item-image"
        case ABOUT_SECTION_TYPE:
          return "about__me-image"
      }
  }

  return (
    <div
      className={SwitchImageType(type)}
      id={type == "profile_picture_type" ? 'main_image_container' : null}
      style={type === PORTFOLIO_TYPE ? onLoadImageStyle : null}>
        {isLoading && <Placeholder type={type} />}
      <img 
        id={type == "profile_picture_type" ? 'main_image_image' : null}
        src={src} 
        alt={alt} 
        onLoad={handleImageLoading} 
      />
    </div>
  )
}
