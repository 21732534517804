import React, { useState, useEffect } from "react";
import "./Certificate.css";
import image from "../../assets/magento_logo.png";
import { Image } from "../Image/Image.component";
import { HEXAGON_TYPE } from "./Certificate.type";

const Certificate = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const handleWindowSizeChange = () => {
    setScreenWidth(window.innerWidth);
  }

  const isMobile = screenWidth <= 600;

  return (
    <a href="#advantages">
      <div className="wrapper">
        <div className="square">
          {!isMobile ? (
            <>
              <span className="title">Magento 2 certified</span>
              <span className="description">adobe developer</span>
            </>
          ) : null}
        </div>
        <div className="hexagon">
          <Image
            src={image}
            alt="Image of Magento certificate"
            type={HEXAGON_TYPE}
          />
          {isMobile ? (
            <div className="mobile__shining__placeholder__wrapper"><div className="mobile__shining__placeholder"></div></div>
          ) : (
            <div className="shining__placeholder" />
          )}
        </div>
      </div>
    </a>
  );
};

export default Certificate;
