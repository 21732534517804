export const banner_description = 'My website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. Tracking will be enabled only upon your explicit consent.';
export const modal_content_description = 'Cookies are small files that websites place on users\' devices to collect data and enhance user experience. They can remember preferences, and track browsing habits. You can modify your preferences regarding cookies and tracking at any time.';
export const cookie_groups_data = [
    {
        'name': 'Strictly Necessary Cookies',
        'id': 'essential',
        'description': 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
        'is_restricted': true
    },
    {
        'name': 'Analytics Cookies',
        'id': 'analytics',
        'description': 'Analytics cookies help website owners understand how visitors interact with the website by collecting and reporting information anonymously. This data allows the website to analyze trends, optimize content, and improve the overall user experience.',
        'is_restricted': false
    }
];
export const cookies_expiration_time = 180;
export const action_type_allow_all = 'allow all';
export const action_type_reject_all = 'reject all';
export const action_type_save_prefs = 'save prefs';