import React, {useEffect, useState} from 'react';
import { useStore } from '../../store/store';
import CookieBannerComponent from './CookieBannerComponent';
import './_module.css';

const CookieWidgetComponent = () => {
  const [{ showCookieBanner, isUserConsentSaved }, dispatch] = useStore();

  useEffect(() => {
    const localStorageData = localStorage.getItem("cookieConsent");
    const cookiesData = document.cookie.split("; ").find((row) => row.startsWith("cookieConsent"));

    if (!localStorageData && !cookiesData) {
      toggleCookieBanner(true)
      toggleUserConsentState()
    }
  }, []);

  const toggleCookieBanner = (state) => {
    dispatch({ type: 'TOGGLE_COOKIE_BANNER', payload: state });
  };

  const toggleUserConsentState = () => {
    dispatch({type: 'SET_USER_CONSENT_STATE', payload: true})
  }


  return (
    <>
        <div className='cookie-banner-widget' data-role='cookie-banner-widget' onClick={() => toggleCookieBanner(true)}>
            <span>Menage cookie preferences</span>
        </div>
        { showCookieBanner && <CookieBannerComponent /> }
    </>
  )
};

export default CookieWidgetComponent;
